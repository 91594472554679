var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "empty-investors-table"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/person--grey.svg")
    }
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v("You have no investors on your list")]), _vm.manageInvestorsEnabled ? _c('div', {
    on: {
      "click": function click($event) {
        return _vm.goToInvestorForm();
      }
    }
  }, [_c('sygni-rounded-button', {
    staticClass: "filled red",
    attrs: {
      "selectable": false,
      "plus-icon": true
    }
  }, [_vm._v(" Add new investor ")])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }