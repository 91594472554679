
























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniPagination from "@/components/table/SygniPagination.vue";
import ProductTable from "@/modules/genprox/modules/fund/modules/capital-rise/modules/products/components/ProductTable.vue";
import ProductFilters from "@/modules/genprox/modules/fund/modules/capital-rise/modules/products/components/ProductFilters.vue";
import RegisterationForm from "@/modules/auth/components/Register.vue";
import EmptyInvestorsTable from "@/modules/genprox/modules/fund/modules/capital-rise/components/EmptyInvestorsTable.vue";
import {mapState} from "vuex";
import { TableData } from '@/modules/genprox/modules/profile/store/types';


@Component({
  components: {
    EmptyInvestorsTable,
    RegisterationForm,
    ProductFilters,
    ProductTable,
    SygniPagination,
    UserPresentation,
    SygniContainerTitle,
    SygniRoundedButton
  },
  computed: mapState('products', {
    productsTable: (state: any) => state.productsTable,
    isBusy: (state: any) => state.productsTableIsBusy,
  }),
})
export default class ProductsModule extends Vue {
  productsTable!: TableData<any>;
  filtersQueryString = '';
  isBusy!: boolean;

  applyFiltersEvent(queryString: string) {
    this.filtersQueryString = queryString;
    (this.$refs.productTable as ProductTable).onFiltersChange(queryString);
  }

  async mounted() {
    await this.$store.dispatch('products/getProducts');
  }
}
